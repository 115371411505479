import DataTable from "react-data-table-component";
import './AllFacilities.css';
import { Facility } from "./IFacilities";
import { useEffect, useState } from "react";
import axios from "axios";
import { formatDate, formatNumber } from "../../utils/PresentationUtils";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedCycle, setSelectedEntity } from "../../store/entitySlice";
import {BsHouses} from "react-icons/bs";


function AllFacilities(){
    const [facilities, setFacilities] = useState<Array<Facility | null>>([]);

    useEffect(() => {
        const getPayslips = async () => {
            try {
                const getFacilities = await axios.get(`/api/account/summary/`);
                const newFacilities = await getFacilities?.data;
                setFacilities(newFacilities ? newFacilities : null);
            } catch (error) {
                console.error('Fetch error:', error);
            }    
        };

        getPayslips();
    }, []);

    const columns = [
        {
            name: 'Facility',
            selector: (row: Facility) => row?.name,
            sortable: true,
        },
        {
            name: 'Current Cycle',
            selector: (row: Facility) => formatDate(row?.currentCycle),
            sortable: true,
        },
        {
            name: 'Resolved Alerts',
            selector: (row: Facility) => row?.resolvedAlerts + '/' + row?.totalAlerts,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: Facility) => row?.status === true ? 'Completed' : 'Review',
            sortable: true,
            cell: (row: Facility) => <span className='cell-button-review'>{row?.status === true ? 'Completed' : 'Review'}</span>
        },
        {
            name: 'Employees',
            selector: (row: Facility) => formatNumber(row?.employees),
            sortable: true,
        },
        {
            name: 'Total payroll ($)',
            selector: (row: Facility) => formatNumber(row?.totalPayroll),
            sortable: true,
        },
        {
            name: 'High Risk ($)',
            selector: (row: Facility) => formatNumber(row?.highRisk),
            sortable: true,
        },
        {
            name: 'Medium Risk ($)',
            selector: (row: Facility) => formatNumber(row?.mediumRisk),
            sortable: true,
        },
        {
            name: 'Low Risk ($)',
            selector: (row: Facility) => formatNumber(row?.lowRisk),
            sortable: true,
        }
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleRowClicked = (row: Facility, event: any) => {
        dispatch(setSelectedEntity(row?.id?.toString()));
        dispatch(setSelectedCycle(row?.currentCycle));
        navigate(`/${row?.id}/${row?.currentCycle}/Overview`);
        mixpanel.track('Open facility from all facilities');
    };

    return (
        facilities && facilities?.length === 0 ? 
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop> 
        :
        <div className="all-facilities">
            <div className="all-facilities-header">
                <BsHouses className='all-facilities-icon' />
                <span className='all-facilities-header-txt'>All Facilities</span>
            </div>
            <div className="all-facilities-table-wrapper">
                {
                    facilities && 
                    <DataTable
                        columns={columns}
                        data={facilities as Facility[]}
                        defaultSortFieldId={0}
                        theme="default"
                        responsive
                        dense
                        onRowClicked={handleRowClicked}
                    />
                }
            </div>
        </div>
    )
}

export default AllFacilities;