import {useEffect, useState} from 'react';
import './Sidebar.css';
import SidebarItem from "../SidebarItem/SidebarItem";
import {useLocation} from "react-router-dom";
import {MdOutlineGroupRemove, MdOutlinePolicy, MdOutlineMoreTime} from "react-icons/md";
import {BsHouses} from "react-icons/bs";
import { TbTable } from "react-icons/tb";
import { MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedEntity } from '../../store/entitySlice';
import UploadModal from '../UploadModal/UploadModal';
import UploadStatusModal from '../UploadStatusModals/UploadStatusModal';
import SyncStatusModal from '../SyncStatusModals/SyncStatusModal';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import PPOUploadModal from "../PPO/PPOUploadModal/PPOUploadModal";
import { ConfirmationDialog } from "../ConfirmationDialog/confirmationDialog";
import useCustomParams from '../../hooks/useCustomParams';
import LoginPanel from '../LoginPanel/loginPanel';


function Sidebar (props: any){
      const [afandi, setAfandi] = useState(false);
      const [syncModalIsOpen, setSyncModalIsOpen] = useState(false);
      const [openPPOConfirmationDialog, setOpenPPOConfirmationDialog] = useState(false);
      const [blockAccountViewRoles, setBlockAccountViewRoles] = useState<string[]>([]);
      const { entity, cycle } = useCustomParams();

      const location = useLocation();

      const entities = useSelector((state: any) => state?.entityReducer?.entities);
      const selectedEntityName = useSelector((state: any) => state?.entityReducer?.selectedEntityName);
      const selectedEntityId = useSelector((state: any) => state?.entityReducer?.selectedEntity);
      const selectedEntityData = entities?.find((x: any) => {
        if(x?.id?.toString() === selectedEntityId?.toString())
            return x;
        });

      const dispatch = useDispatch();
      
      const isUltimateCare = false;

      const navigate = useNavigate();

      const [uploadStatusPPO, setUploadStatusPPO] = useState('');

      useEffect(() => {
            const querySearchParams = location?.search ? location?.search : '';
            if (entities?.length > 0){ 
                let selectedEntityData = entity !== undefined ? entities?.find((x:any) => x?.id?.toString() === entity?.toString()) : entities[0];
                if(selectedEntityData?.plan === 'PPO')
                {
                    props.setShowPPO(true);
                    const innerPageLink = location?.hash ? location?.hash : '';
                    const idNavigate =  selectedEntityData?.id ? selectedEntityData?.id?.toString() : null;
                    const cycleNavigate =  selectedEntityData?.ppo_cycles?.length > 0 ?  selectedEntityData?.ppo_cycles[0] : null;
                    if(idNavigate && cycleNavigate)
                    {
                        const pathBreakdown = location.pathname.split('/');
                        const pathName = pathBreakdown.join('/');
                        navigate(`${pathName}${innerPageLink}${querySearchParams}`);
                    }
                }
                loadConfigData();
            }
      }, [entity, entities]);

      function onDoubleClick(){
            console.log(location?.pathname);
            setAfandi(true);
            setTimeout(() => {
                  setAfandi(false);
            }, 200);
      }
      
      const isSuperUser = localStorage.getItem('is_superuser') === "true";
      const userRole = localStorage.getItem('user_role') ?? '';

      function OnEntityChanged(event: any){
        const entityID = entities.filter((entity: any) => entity?.name === event?.target?.value)[0]?.id?.toString();
        dispatch(setSelectedEntity(entityID));
      }

      const [modalIsOpen, setIsOpen] = useState(false);
      const [modalIsPPOOpen, setIsPPOOpen] = useState(false);
      const [toTriggerUploadStatus, setToTriggerUploadStatus] = useState(false);
      const [uploadProgress, setUploadProgress] = useState({});

      function generateUploadCell(){
            const onSelect = () =>{
                setIsOpen(true);
            };
    
            return <div className="EntityPortalUploadCell">
                <div>
                    <button data-track='Upload new cycle' className="entity-portal-select-btn" onClick={() => onSelect()}><img src='/upload.svg' alt='upload'/>Upload Payroll Register</button>
                </div>
            </div>
      }

    function generatePPOUploadCell(){
        const onSelect = () =>{
            setIsPPOOpen(true);
        };

        if (!selectedEntityData.enable_ppo_file_upload)
            return ''

        return <div className="EntityPortalPPOUploadCell">
            <div>
                <button data-track='Upload new ppo cycle' className="entity-portal-select-btn" onClick={() => onSelect()}><img src='/upload.svg' alt='upload'/>Upload New files</button>
            </div>
        </div>
    }

      function syncIntegration(){
            axios.post(`/api/payslips/sync/${selectedEntityData?.id}`, {
            }).then((res) => {
                console.log(res?.data?.message);
            });
      }

      function generateSyncCell(){
            const onSyncSelect = () =>{
                syncIntegration();
                setSyncModalIsOpen(true);
            };
    
            return <div className='EntityPortalUploadCell'>
                        <button className="entity-portal-select-btn" onClick={() => onSyncSelect()}><img src='/upload.svg' alt='upload'/>Get Latest Payroll</button>
                   </div>
      }

      function loadConfigData() {
        axios.get(`/api/system/config/${entity}/dynamic_config/`).then((res) => {
            if (res.data){
                for (let conf of res.data) {
                    if (conf.key === 'BlockAccountView'){
                        setBlockAccountViewRoles(conf.value)
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
        })
      }
      
      function renderSideBar() {
          return (
              <div className="sidebar" data-testid="Sidebar">
                  <img src={"/Logo.svg"} className={"sidebar-logo-new"} onDoubleClick={onDoubleClick} alt='celery'/>
                  <div className="entity-select-area">
                      <Select displayEmpty className="header-select" onChange={OnEntityChanged} value={selectedEntityName ?? "loading"}>
                          {entities?.map((x: any, index: number) => <MenuItem data-track='Change entity' data-track-props={'entity selected: '+x?.name}  className='option-design' key={x?.name} value={x?.name}>{index === 0 ? x?.name : x?.name }</MenuItem> )}
                      </Select>

                      <UploadModal toTriggerStatus={setToTriggerUploadStatus} modalIsOpen={modalIsOpen} entity={selectedEntityData?.id} setIsOpen={setIsOpen} setUploadProgress={setUploadProgress} uploadProgress={uploadProgress}/>
                      <UploadStatusModal isTriggered={toTriggerUploadStatus}
                                         setIsTriggered={setToTriggerUploadStatus}
                                         retryUpload={setIsOpen}
                                         hasMultipleEntity={props.entityData?.length > 0}
                                         selectedEntityName={selectedEntityName}
                                         selectedEntityID={selectedEntityData?.id}
                                         setUploadProgress={setUploadProgress}
                                         uploadProgress={uploadProgress}
                      />
                  </div>
                  {isUltimateCare ? generateSyncCell() : generateUploadCell()}
                  {isUltimateCare &&
                      <SyncStatusModal isTriggered={syncModalIsOpen} setIsTriggered={setSyncModalIsOpen}/>
                  }
                  <div className='sidebar-scroll-area'>
                      <SidebarItem path={`/${entity}/${cycle}/Overview`} text="Overview" icon={<img src='/icon.svg' alt='Overview'/>} disabled={!props.hasData}></SidebarItem>
                      <SidebarItem path={`/${entity}/${cycle}/report`} text="Report" icon={<img src='/chart-square.svg' alt='report'/>} disabled={!props.hasData}></SidebarItem>
                      <div className="subItem" hidden={location?.pathname !== `/${entity}/${cycle}/payslips/`}>
                          <SidebarItem path={`/${entity}/${cycle}/payslips`} text="Payslips" icon={<img src='/element-3.svg' alt='entities'/>} disabled={true}></SidebarItem>
                      </div>
                      <SidebarItem path={`/${entity}/${cycle}/terminations`} text="Terminations" icon={<MdOutlineGroupRemove className='employee-turnover-sidebar-icon' />}></SidebarItem>
                      <SidebarItem path={`/${entity}/${cycle}/overtime`} text="Overtime" icon={<MdOutlineMoreTime className='overtime-sidebar-icon' />}></SidebarItem>
                      <SidebarItem path={`/${entity}/${cycle}/policy_edit`} text="Your Policy" icon={<MdOutlinePolicy style={{fontSize:20, color:'#0E3C48'}}/>}></SidebarItem>
                      <SidebarItem path={`/${entity}/${cycle}/dashboard`} text="Dashboard" icon={<img src='/element-4.svg' alt='dashboard'/>} disabled={!props.hasData}></SidebarItem>
                      <div className="subItem" hidden={location?.pathname !==`/${entity}/${cycle}/dashboard`}>
                          <SidebarItem path="#gross-change" text="Gross Change"></SidebarItem>
                          <SidebarItem path="#hours-by-type" text="Hours By Type"></SidebarItem>
                          <SidebarItem path="#employees-by-total-hours" text="Employees By Total Hours"></SidebarItem>
                          <SidebarItem path="#one-off-payments" text="One Off Payments"></SidebarItem>
                          <SidebarItem path="#earnings-breakdown" text="Earnings Breakdown"></SidebarItem>
                          <SidebarItem path="#cost-analysis" text="Cost Analysis"></SidebarItem>
                      </div>
                      <SidebarItem path={`/${entity}/${cycle}/employee_dashboard`} text="Employee Dashboard" icon={<img src='/user-square.svg' alt='employee dashboard'/>} disabled={!props.hasData}></SidebarItem>
                      {(blockAccountViewRoles.length === 0 || blockAccountViewRoles.indexOf(userRole) === -1) && <SidebarItem path={`/${entity}/${cycle}/all_facilities`} text="All Facilities" icon={<BsHouses className='overtime-sidebar-icon' />} ></SidebarItem>}
                      {(blockAccountViewRoles.length === 0 || blockAccountViewRoles.indexOf(userRole) === -1) && <SidebarItem path={`/${entity}/${cycle}/analysis`} text="Analysis" icon={<TbTable className='analysis-sidebar-icon' />}></SidebarItem>}
                      {isSuperUser && <SidebarItem path={`/${entity}/${cycle}/AutoComment_edit`} text="Auto Comment" icon={<img src='/candle-2.svg' alt='config tests'/>} ></SidebarItem>}
                      {isSuperUser && <SidebarItem path={`/${entity}/${cycle}/config_tests`} text="config tests" icon={<img src='/candle-2.svg' alt='config tests'/>} ></SidebarItem>}
                      {isSuperUser && <SidebarItem path={`/${entity}/${cycle}/create_user`} text="create user" icon={<img src='/UserAdd-Linear.svg' alt='create user'/>} disabled={!props.hasData}></SidebarItem>}
                      {isSuperUser && <SidebarItem path={`/${entity}/${cycle}/dynamic_config`} text="dynamic config" icon={<img src='/candle-2.svg' alt='dynamic config'/>} disabled={!props.hasData}></SidebarItem>}
                      {isSuperUser && <SidebarItem path={`/${entity}/${cycle}/fields`} text="fields managment" icon={<img src='/candle-2.svg' alt='fields'/>}></SidebarItem>}
                      {isSuperUser && <SidebarItem path={`/${entity}/${cycle}/insights`} text="insights" icon={<img src='/candle-2.svg' alt='insights'/>}></SidebarItem>}
                      {isSuperUser && <SidebarItem path={`/${entity}/${cycle}/deep_insights`} text="deep insights" icon={<img src='/candle-2.svg' alt='deep_insights'/>}></SidebarItem>}
                      {isSuperUser && <SidebarItem path={`/${entity}/${cycle}/approve_payslips`} text="approve payslips" icon={<img src='/candle-2.svg' alt='approve payslips'/>}></SidebarItem>}
                      <LoginPanel setLoggedIn={props.setLoggedIn}/>
                      <div hidden={!afandi}>
                          AFANDI!
                      </div>
                  </div>
              </div>
          );
      }

      function renderSideBarPPO() {
          return (
              <div className="sidebar" data-testid="PPOSidebar">
                  <img src={"/Logo.svg"} className={"sidebar-logo-new"} alt='celery'/>
                  <div className="entity-select-area">
                      <Select displayEmpty className="header-select" onChange={OnEntityChanged} value={selectedEntityName}>
                          {entities?.map((x: any, index: number) => <MenuItem className='option-design' key={x?.name} value={x?.name}>{index === 0 ? x?.name : x?.name }</MenuItem> )}
                      </Select>

                      <PPOUploadModal modalIsOpen={modalIsPPOOpen} setModalIsOpen={setIsPPOOpen} setUploadStatus={setUploadStatusPPO}
                                      setOpenPPOConfirmationDialog={setOpenPPOConfirmationDialog} />
                      <ConfirmationDialog
                          onClose={() => {setOpenPPOConfirmationDialog(false);}}
                          titleTxt="Processing your data..."
                          content="We will notify you by email when it's ready for review. You can safely close this dialog."
                          open={openPPOConfirmationDialog}
                          showCheckmark={true}
                          hideCancelBtn={true}
                      />
                  </div>

                  {generatePPOUploadCell()}

                  <div className='sidebar-scroll-area'>
                      <SidebarItem path={`/${entity}/${cycle}`} text="Overview" icon={<img src='/icon.svg' alt='Overview'/>}></SidebarItem>
                      <LoginPanel setLoggedIn={props.setLoggedIn}/>
                  </div>
              </div>
          );
      }

      if (props.isPPO) {
          return renderSideBarPPO();
      } else {
          return renderSideBar();
      }
}

export default Sidebar;